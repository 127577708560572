import React from 'react';
import { Link } from 'react-router-dom';


function AnchorInfoCard({ anchor }) {
    return (
        <div className="overflow-visible relative max-w-sm mx-auto bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex items-center gap-6 dark:bg-slate-800 dark:highlight-white/5 my-5 w-full md:w-1/2 lg:w-1/4  ml-12 shadow">
      <Link to={`/query?uid=${anchor.uid}`} className="no-underline w-full"> {/* Wrap with Link and use to attribute */}
        
          <img className="absolute -left-8 w-28 h-28 rounded-full shadow-xl" src={anchor.face} alt={anchor.uname} />
          <div className="flex flex-col py-5 pl-24">
            <strong className="text-slate-900 text-lg font-bold dark:text-slate-200">{anchor.uname}</strong>
            <span className="text-slate-500 text-sm font-bold dark:text-slate-400">房间号: {anchor.roomid}</span>
            <span 
            className="text-slate-500 text-sm font-bold dark:text-slate-400 overflow-hidden whitespace-nowrap text-overflow-ellipsis"
            style={{ maxWidth: '100%' }} 
            >
            UID: {anchor.uid}
            </span>
          </div>
          </Link>
        </div>
      
    );
  }

export default AnchorInfoCard;
