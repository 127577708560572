import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../pages/NavigationBar';
import AnchorInfoCard  from '../pages/AnchorInfoCard';
import axios from 'axios';
import ReactEcharts from 'echarts-for-react';

function HomePage() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    if (id && token) {
      const requestBody = {
        id: parseInt(id),
        token: token
      };

      axios.post('https://turn.vup.name/api/v1/info', requestBody)
        .then(response => {
          setData(response.data);
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }, []);

  // 准备用于图表的数据
  const chartData = data ? data.live_day_counts.map(item => ({
    name: `Day ${item.day}`,
    value: [item.day, item.num]
  })) : [];

  // 配置 ECharts 图表
  const getOption = () => {
    // 先对 chartData 按照 day 的大小进行排序
    const sortedChartData = chartData.sort((a, b) => a.value[0] - b.value[0]);

    // 计算最大的 num 值
    const maxNum = Math.max(...sortedChartData.map(item => item.value[1]));

    // 生成分层线，每20为一个层级
    const markLines = [];
    for (let i = 40; i <= maxNum; i += 40) {
      markLines.push({ yAxis: i, name: `阈值 ${i}` });
    }

  
    return {
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          if (params && params.length > 0) {
            const dataIndex = params[0].dataIndex;
            const day = sortedChartData[dataIndex].value[0];
            const num = sortedChartData[dataIndex].value[1];
            return `有效开播天数满${day}天的有${num}个`; 
          }
          return ''; // 如果没有数据，则返回空字符串
        }
      },
      grid: {
        top: '0%',
        bottom: '0%',
        left: '0%',
        right: '0%',
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
      },
      yAxis: {
        type: 'value',
        splitLine: { show: false },
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: { show: false },
      },
      series: [{
        data: sortedChartData,
        smooth: true,
        type: 'line',
        symbol: "none",
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: '#e5566a' },
              { offset: 1, color: 'rgba(247, 108, 130, 0.1)' },
            ],
            global: false,
          },
        },
        lineStyle: { color: '#e5566a' },
        markLine: {
          symbol: 'none',
          data: markLines,
          lineStyle: {
            color: 'red',
            type: 'dashed',
          },
          label: {
            position: 'end',
          }
        }
      }],
      
    };
  };
  
  

  return (
    <div className="bg-gray-200 min-h-screen">
      <NavigationBar className="relative"/>
      <div className="container mx-auto p-4 flex flex-col items-center">
      {data && (
          <>
            {/* 新主播数 */}
            <h1 className="text-xl font-semibold text-gray-800 mb-1 pl-2 md:w-full text-left">数据简介</h1>
            <div className="stats bg-white shadow w-full mb-4 flex text-gray-200 font-bold">
              <div className="stat flex-1 flex flex-col items-center justify-center">
                <div className="stat-title text-gray-600 font-bold">总获取主播数</div>
                <div className="stat-value text-gray-800 font-bold">{data.total_count}</div>
                <div className="stat-desc text-gray-600 font-bold">数据库中所有的主播总和</div>
              </div>
              <div className="stat flex-1  flex flex-col items-center justify-center">
                <div className="stat-title text-gray-600 font-bold">老主播</div>
                <div className="stat-value text-gray-800 font-bold">{data.new_anchor_counts['0']}</div>
                <div className="stat-desc text-gray-600 font-bold">老主播数量</div>
              </div>
              <div className="stat flex-1 flex flex-col items-center justify-center">
                <div className="stat-title text-gray-600 font-bold">新主播</div>
                <div className="stat-value text-gray-800 font-bold">{data.new_anchor_counts['1']}</div>
                <div className="stat-desc text-gray-600 font-bold">新主播数量</div>
              </div>
              <div className="stat flex-1 flex flex-col items-center justify-center">
                <div className="stat-title text-gray-600 font-bold">签约主播</div>
                <div className="stat-value text-gray-800 font-bold">{data.new_anchor_counts['2']}</div>
                <div className="stat-desc text-gray-600 font-bold">已经签约主播数量</div>
              </div>
              
            </div>

            {/* ECharts 图表 */}
            <h1 className="text-xl font-semibold text-gray-800 mb-1 pl-2 md:w-full text-left">有效开播</h1>
            <div className="bg-white shadow-lg rounded-2xl w-full mb-4">
              <ReactEcharts option={getOption()} />
            </div>

            {/* 签约数 */}
            <h1 className="text-xl font-semibold text-gray-800 mb-1 pl-2 md:w-full text-left">主播数据</h1>
            <div className="flex w-full mb-4">
            <div className="stats bg-white shadow flex-grow mr-2 flex text-gray-200 font-bold">
              <div className="stat flex-1 flex flex-col items-center justify-center">
                <div className="stat-title text-gray-600 font-bold">未签约</div>
                <div className="stat-value text-gray-800 font-bold">{data.signed_counts['0']}</div>
                <div className="stat-desc text-gray-600 font-bold">数据库中未签约主播</div>
              </div>
              <div className="stat flex-1 flex flex-col items-center justify-center">
                <div className="stat-title text-gray-600 font-bold">已签约</div>
                <div className="stat-value text-gray-800 font-bold">{data.signed_counts['1']}</div>
                <div className="stat-desc text-gray-600 font-bold">数据库中已签约主播</div>
              </div>
            </div>

            {/* 明星等级数 */}
            <div className="stats bg-white shadow flex-none w-4/6 flex text-gray-200 font-bold">
              <div className="stat flex-1 flex flex-col items-center justify-center">
                <div className="stat-title text-gray-600 font-bold">未签约非繁星</div>
                <div className="stat-value text-gray-800 font-bold">{data.star_level_counts['0'] - data.signed_counts['1'] }</div>
                <div className="stat-desc text-gray-600 font-bold">数据库中无繁星星级未签约主播</div>
              </div>
              <div className="stat flex-1 flex flex-col items-center justify-center">
                <div className="stat-title text-gray-600 font-bold">四星繁星</div>
                <div className="stat-value text-gray-800 font-bold">{data.star_level_counts['2']}</div>
                <div className="stat-desc text-gray-600 font-bold">数据库中的四星繁星主播</div>
              </div>
              <div className="stat flex-1 flex flex-col items-center justify-center">
                <div className="stat-title text-gray-600 font-bold">五星繁星</div>
                <div className="stat-value text-gray-800 font-bold">{data.star_level_counts['3']}</div>
                <div className="stat-desc text-gray-600 font-bold">数据库中的五星繁星主播</div>
              </div>
              <div className="stat flex-1 flex flex-col items-center justify-center">
                <div className="stat-title text-gray-600 font-bold">预备星</div>
                <div className="stat-value text-gray-800 font-bold">{data.star_level_counts['4']}</div>
                <div className="stat-desc text-gray-600 font-bold">数据库中的预备星主播</div>
              </div>
            </div>
            </div>
          </>
        )}
        <h1 className="text-xl font-semibold text-gray-800 mb-1 pl-2 md:w-full text-left">未签约</h1>
        <div className="flex flex-wrap gap-1 bg-white shadow-sm rounded-2xl pt-3 pr-5">
        
          {data && data.info.map(anchor => (
            <AnchorInfoCard key={anchor.uid} anchor={anchor} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
