import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NavigationBar from '../pages/NavigationBar';
import Sidebar from '../pages/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faRepeat,faRightFromBracket,faSearch,faMessage  } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';


function QueryPage() {
  const [uid, setUid] = useState('');
  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const [showNewInfo, setShowNewInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    // 解析查询参数
    const query = new URLSearchParams(location.search);
    const uidParam = query.get('uid');

    // 如果 URL 有 UID 参数，设置它并自动触发搜索
    if (uidParam) {
      setUid(uidParam);
      handleSearch(uidParam);
    }
  }, [location]);

  

  const handleSearch = (uidValue = uid) => {
    if (!uidValue) return;
    console.log('UID Value:', uidValue); // 调试输出
    if (!/^\d+$/.test(uidValue)) {
      setError('UID 必须是纯数字');
      return;
    }
    setError('');

    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    if (!id || !token) {
      setError('缺少身份验证信息');
      return;
    }

    setIsLoading(true);

    const requestBody = {
      id: id,
      token: token,
      uid: uidValue
    };

    axios.post('https://turn.vup.name/api/v1/query', requestBody)
      .then(response => {
        // 检查公会信息是否存在，如果不存在，则设置默认值
        if (!response.data.guild || Object.keys(response.data.guild).length === 0) {
          response.data.guild = {
            guild_id: "无",
            guild_intro: "若出现该项信息，则该主播与当前时间下并没有加入任何一个公会，查看该项信息的运营同学如认为该主播有被挖掘的潜力的话，可根据该主播的繁星星级以及繁星任务达成难易程度酌情考虑邀请该主播加入“极游文化”或者“八木百货”！",
            logo: "https://static.hdslb.com/images/member/noface.gif",
            name: "无公会",
            sign_num: 0,
            sign_time: "无"
          };
        }
        setData(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('请求错误！', error);
        setIsLoading(false);
      });

  };

  return (
    <div className="bg-gray-200 min-h-screen">
      <NavigationBar className="relative"/>
      <div className="container mx-auto p-4 flex flex-col items-center">
      <div className="w-full max-w-5xl">
      <div className="form-control w-full max-w-5xl">
        <label className="label"></label>
        <div className="flex items-center space-x-4">
          <input 
            type="text" 
            placeholder="请输入 UID" 
            className="input input-bordered input-accent flex-grow" 
            value={uid}
            onChange={(e) => setUid(e.target.value)}
          />
          <button className="btn btn-accent " onClick={() => handleSearch(uid)}><FontAwesomeIcon icon={faSearch} size="lg" style={{color: "#ffffff",}} /></button>
        </div>

        {error && <p className="text-red-500">{error}</p>}
      </div>
      </div>
        {isLoading && (
          <div className="flex justify-center items-center mt-4">
            <FontAwesomeIcon icon={faSpinner} spin size="2x" className="text-[#00d7c0] mr-2" />
            <p className="text-lg text-[#00d7c0] font-bold ">请求数据中，请稍候...</p>
          </div>
        )}
        {data && (
          <div className="form-control w-full max-w-5xl">
            <UserProfileCard userData={data} />
          </div>
        )}

      {data && (
        <div className="flex flex-col md:flex-row gap-4 mt-4 w-full max-w-5xl">
          
          <div className="flex flex-col flex-grow">
            {data.live && Object.keys(data.live).length > 0 && <LiveCard live={data.live} className="mt-4" />}
            {data.info && Object.keys(data.info).length > 0 && (
            <div className="mt-4">
              {/* 传递 showNewInfo 和 setShowNewInfo 给 InfoCard */}
              <InfoCard 
                info={showNewInfo ? data.new_info : data.info} 
                onToggle={() => setShowNewInfo(!showNewInfo)} 
                className="mb-4"
              />
            </div>
        )}
          </div>
          {data.guild && Object.keys(data.guild).length > 0 && (
            <div className="flex flex-col flex-grow md:flex-shrink-0 md:w-1/3">
              <GuildCard guild={data.guild} />
            </div>
          )}
        </div>
      )}
      </div>

    </div>
  );
}
const formatStarMetrics  = (metrics) => {
  if (metrics === "None") {
    return "无";
  }
  try {
    const metricsArray = JSON.parse(metrics.replace(/'/g, '"'));
    return (
      <table className="table-auto w-full mx-auto bg-white rounded-lg">
        <thead>
          <tr className="text-center">
            <th>金额</th>
            <th>周期</th>
          </tr>
        </thead>
        <tbody>
          {metricsArray.map((metric, index) => (
            <tr key={index} className="text-center">
              <td>{metric.Val}￥</td>
              <td>{metric.DateRange}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } catch (e) {
    console.error('Error parsing star_metrics:', e);
    return "解析错误";
  }
};
function InfoCard({ info, onToggle }) {
  const modalData = info;
  const starMetrics = info.star_metrics;
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive); // 切换激活状态
    onToggle(); // 调用传入的 onToggle 函数
  };

  return (
    
    <div className="card bg-white shadow-xl w-full ">
      {/* 文本内容部分 */}
      <div className="card-body">
      <div className="flex items-center mb-4">
        <h2 className="card-title items-center">信息</h2>
        <button 
          onClick={handleToggle}
          className={`btn btn-sm btn-circle btn-outline tooltip z-50 ${isActive ? 'text-red-500' : ''}`}
          style={{ float: 'right' }} // 按钮位置
          data-tip="点击切换最新数据/历史数据（如无任何变化则证明数据与老数据一致）"
        >
          <FontAwesomeIcon icon={faRepeat} />
        </button>
        </div>
        <div className="overflow-x-auto bg-gray-200 rounded-2xl">
        <div className="card m-4 rounded-2xl">
        <table className="min-w-full mx-auto bg-white rounded-lg shadow overflow-hidden ">
            <tbody>
              <tr className="border-b ">
                <td className="py-2 px-4 font-bold">主播状态</td>
                <td className="text-center" >{info.is_new_anchor === "1" ? "新主播" : "老主播"}</td>
              </tr>
              <tr className="border-b">
                <td className="py-2 px-4 font-bold">签约状态</td>
                <td className="text-center">{info.is_signed === "1" ? "已签约" : "未签约"}</td>
              </tr>
              <tr className="border-b ">
                <td className="py-2 px-4 font-bold">繁星等级</td>
                <td className="text-center">
                    {modalData.star_level === '0' ? "非繁星" :
                     modalData.star_level === '2' ? "⭐⭐⭐⭐" :
                     modalData.star_level === '3' ? "⭐⭐⭐⭐⭐" :
                     modalData.star_level === '4' ? "预备星" :
                     modalData.star_level}
                  </td>
              </tr>
              <tr className="border-b">
                <td className="py-2 px-4 font-bold">繁星任务</td>
                <td className="text-center align-middle rounded-xl  p-1">{formatStarMetrics(starMetrics)}</td>
              </tr>
              <tr>
                <td className="py-2 px-4 font-bold">有效天数</td>
                <td className="text-center">{info.valid_live_day}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
  );
}


function UserProfileCard({ userData }) {
  const hasNameplate = userData.nameplate && userData.nameplate.image_small && userData.nameplate.name;
  const avatarClass = hasNameplate ? "flex flex-col items-center w-full md:w-1/4" : "flex flex-col items-center justify-center w-full md:w-1/4";
  const isMobileDevice = () => {
    return /Mobi|Android|iPhone/i.test(navigator.userAgent);
  };
  return (
    <div className="flex flex-col bg-base-100 shadow-lg p-5 md:flex-row gap-4 mt-4 rounded-lg w-full ">
      {/* 左侧部分：头像和勋章 */}
      <div className={avatarClass}>
        {userData.face && <img src={userData.face} alt="头像" className="w-32 h-32 rounded-full shadow-xl " />}
        {userData.nameplate && (
          <div className="flex mt-6 items-center">
            {userData.nameplate.image_small && <img src={userData.nameplate.image_small} className="w-8 h-8 tooltip" data-tip={userData.nameplate.level}></img>}
            {userData.nameplate.name && <p className="ml-2 text-xl font-bold tooltip" data-tip={`${userData.nameplate.condition} {${userData.nameplate.level}}`}>{userData.nameplate.name}</p>}
          </div>
        )}
      </div>

      {/* 右侧部分：用户信息 */}
      <div className="flex flex-col w-full bg-gray-200 py-2 pl-4 shadow rounded-lg p-2 md:w-3/4">
        <div className="m-2 leading-loose font-bold">
          {userData.uname && (
            <p>
              <span className="inline-block min-w-[80px] bg-white px-2 shadow rounded-lg font-bold mr-2 mb-2">
                <center>用户名</center>
              </span> 
              {userData.uname}
              <button className="btn btn-sm ml-2" onClick={() => {
              const url = isMobileDevice()
                ? `bilibili://space/${userData.uid}`
                : `https://message.bilibili.com/?spm_id_from=333.999.0.0#/whisper/mid${userData.uid}`;
              window.open(url, '_blank');
            }}><FontAwesomeIcon icon={faMessage} bounce /> 私信 </button>
            </p>
          )}
          {userData.uid && (
            <p>
              <span className="inline-block min-w-[80px] bg-white px-2 shadow rounded-lg font-bold mr-2 mb-2">
                <center>UID</center>
              </span>
              {userData.uid}
            </p>
          )}
          {userData.roomid && (
            <p>
              <span className="inline-block min-w-[80px] bg-white px-2 shadow rounded-lg font-bold mr-2 mb-2">
                <center>房间号</center>
              </span>
              {userData.roomid}
            </p>
          )}
          {userData.sign && (
            <p>
              <span className="inline-block min-w-[80px] bg-white px-2 shadow rounded-lg font-bold mr-2 mb-2">
                <center>简介</center>
              </span>
              {userData.sign}
            </p>
          )}
          {userData.birthday && (
            <p>
              <span className="inline-block min-w-[80px] bg-white px-2 shadow rounded-lg font-bold mr-2">
                <center>生日</center>
              </span>
              {userData.birthday}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}



function LiveCard({ live }) {

  const [bgImageUrl, setBgImageUrl] = useState('');

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone/i.test(navigator.userAgent);
  };

  useEffect(() => {
    if (live.cover_from_user) {
      const img = new Image();
      img.referrerPolicy = 'no-referrer';
      img.src = live.cover_from_user;
      img.onload = () => {
        setBgImageUrl(live.cover_from_user);
      };
    }
  }, [live.cover_from_user]);

  const cardStyle = {
    backgroundImage: `url(${bgImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div className="card bg-base-100 shadow-xl flex flex-col md:flex-row" style={cardStyle}>
      {/* 文本内容部分 */}
      <div className="card-body flex-grow bg-white bg-opacity-25 rounded-lg">
        <div className="flex justify-between items-center mb-2">
          <h2 className="card-title">直播</h2>
          <button className="btn btn-sm" onClick={() => {
              const url = isMobileDevice()
                ? `bilibili://live/${live.room_id}`
                : `https://live.bilibili.com/${live.room_id}`;
              window.open(url, '_blank');
            }}><FontAwesomeIcon icon={faRightFromBracket} /> 打开直播间 </button>
        </div>
        <div className="mb-3 leading-relaxed py-2 pl-4 shadow backdrop-blur-sm bg-white/50 rounded-lg font-bold ">
          <div className="flex flex-col md:flex-row -mx-2">
            <div className="w-full md:w-2/5 px-2">
              <p className="m-2">直播分区 : {live.area_v2_parent_name}</p>
              <p className="m-2">房间号码 : {live.room_id}</p>
              <p className="m-2">直播状态 : {live.live_status === 1 ? "直播中" : "未直播"}</p>
            </div>
            <div className="w-full md:w-3/5 px-2">
              <p className="m-2">直播时间 : {live.live_time === 0 ? '无开播时长信息' : new Date(live.live_time * 1000).toLocaleString()}</p>
              <p className="m-2">人气信息 : {live.online}</p>
              <p className="m-2">直播标题 : {live.title}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}






function GuildCard({ guild }) {
  return (
    <div className="card bg-white shadow-xl">
      {/* 公会头像居中显示 */}
      <div className="text-center">
        <img src={guild.logo} alt="公会Logo" className="inline object-cover h-28 w-28 rounded-full shadow-xl mt-6" />
      </div>
      {/* 文本内容部分 */}
      <div className="card-body">
        <h3 className="font-bold">公会</h3>
        <div className="mb-2 leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold">
        <p>公会ID: {guild.guild_id}</p>
        <p>公会名称: {guild.name}</p>
        </div>
        <h3 className="font-bold">公会简介: </h3>
        <p className="mb-1 leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold pr-2">{guild.guild_intro}</p>
        <h3 className="font-bold">其它信息: </h3>
        <div className="mb-1 leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold">
        <p className="m-1">签约数量: {guild.sign_num}</p>
        <p className="m-1">签约时间: {guild.sign_time}</p>
        </div>
      </div>
    </div>
  );
}




export default QueryPage;