import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Modal from 'react-modal';
import Watermark from '../pages/Watermark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft  } from '@fortawesome/free-solid-svg-icons';



Modal.setAppElement('#root'); 

const navigation = [
  { name: '首页', href: '/', current: true },
  { name: '主播发掘', href: '/excavate', current: false },
  { name: '主播公会/社团查询', href: '/query', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const navigate = useNavigate();
  const location = useLocation(); 
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState('');
  const [token, setToken] = useState('');

  const handleBack = () => {
    navigate(-1);
  };
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    localStorage.setItem('id', id);
    localStorage.setItem('token', token);
    closeModal();
  };

  useEffect(() => {
    const storedId = localStorage.getItem('id');
    const storedToken = localStorage.getItem('token');
    if (storedId) setId(storedId);
    if (storedToken) setToken(storedToken);
  }, []);

  return (
    
    <Disclosure as="nav" className="bg-white shadow-lg relative">
      
      {({ open }) => (
        <>
        <Watermark id={id} token={token} />
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                <img
                  className="h-8 w-auto cursor-pointer"
                  src="https://s2.loli.net/2024/01/30/EhXnfol8qtwkzK3.png"
                  alt="logo"
                  onClick={openModal}
                />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4 font-semibold antialiased">
                {navigation.map((item) => (
                    <div 
                      key={item.name} 
                      className={classNames(
                        location.pathname === item.href ? 'bg-slate-400 text-white font-semibold' : 'text-black hover:bg-slate-400 hover:text-white', // 动态设置背景和文字颜色
                        'rounded-md px-3 py-2 text-sm font-medium relative group font-semibold'
                      )}>
                      <a
                        href={item.href}
                        aria-current={item.current ? 'page' : undefined}
                        className="flex items-center"
                      >
                        {item.name}
                      </a>
                      {item.children && (
                        <div className="absolute left-0 top-full bg-white shadow rounded-md py-1 min-w-max hidden group-hover:block z-50"> {/* 添加 z-50 来确保高 z-index */}
                          {item.children.map((child) => (
                            <a
                              key={child.name}
                              href={child.href}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 whitespace-nowrap"
                            >
                              {child.name}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {showModal && (
                  <div className="modal modal-open">
                    <div className="modal-box">
                      <span className="text-xl font-bold">输入您的校验信息</span>
                      
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text font-bold">士大夫 ID</span>
                        </label>
                        <input 
                          type="text"
                          className="input input-bordered w-full" 
                          value={id} 
                          onChange={(e) => setId(e.target.value)}
                        />
                      </div>

                      <div className="form-control">
                        <label className="label">
                          <span className="label-text font-bold">Token</span>
                        </label>
                        <input 
                          type="text"
                          className="input input-bordered w-full" 
                          value={token} 
                          onChange={(e) => setToken(e.target.value)}
                        />
                      </div>

                      <div className="modal-action">
                        <button className="btn btn-active btn-neutral" onClick={handleSave}>保存</button>
                        <button className="btn" onClick={closeModal}>关闭</button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
               
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {/* 导航项 */}
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    location.pathname === item.href
                      ? 'bg-slate-400 text-white'
                      : 'text-black hover:bg-slate-400 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
          <div className="sm:hidden"> {/* 仅在小屏幕上显示 */}
          <button 
            onClick={handleBack}
            className="fixed bottom-4 right-4 z-50 px-3 py-2 rounded-2xl text-base font-medium text-black bg-white hover:bg-slate-400 hover:text-white shadow-lg border border-red-300"
          >
            <FontAwesomeIcon icon={faCaretLeft} /> 返回上页
          </button>

      </div>


          
        </>
        
      )}
      
    </Disclosure>
  )
}