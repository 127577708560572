import React, { useState, useEffect } from 'react';

const Watermark = ({ id, token }) => {
  const [watermarkUrl, setWatermarkUrl] = useState('');
  const hiddenClasses = ['__-inspector-hide-shortcut__', '__web-inspector-hide-shortcut__'];
  const watermarkId = 'watermark-container';
  const watermarkSelector = 'div[data-watermark="true"]';

  // 创建水印图像
  const createWatermark = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 1000;
    canvas.height = 1000;
    const ctx = canvas.getContext('2d');

    ctx.font = '10px Arial';
    ctx.fillStyle = 'rgba(50, 50, 150, 0.15)';

    const rows = 12;
    const columns = 11;
    const angle = Math.PI / 4;
    const truncatedToken = token.slice(0, 8);
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        ctx.save();
        ctx.translate((j + 0.5) * canvas.width / columns, (i + 0.5) * canvas.height / rows);
        ctx.rotate(angle);
        ctx.fillText(`士大夫ID: ${id}`, -50, -12);
        ctx.fillText(`追踪码：${truncatedToken}`, -50, 0);
        ctx.fillText(`仅限浏览外传必究`, -50, 12); 
        ctx.fillText(`八木百货Yagimall`, -50, 24);
        ctx.restore();
      }
    }

    return canvas.toDataURL('image/png');
  };

  // 添加或恢复水印元素
  const restoreWatermark = () => {
    let watermarkDiv = document.getElementById(watermarkId);
    if (!watermarkDiv) {
      watermarkDiv = document.createElement('div');
      watermarkDiv.id = watermarkId;
      watermarkDiv.setAttribute('data-watermark', 'true');
      document.body.appendChild(watermarkDiv);
    }

    // 设置或重置水印的样式
    watermarkDiv.style.position = 'fixed';
    watermarkDiv.style.top = '0px';
    watermarkDiv.style.left = '0px';
    watermarkDiv.style.width = '100%';
    watermarkDiv.style.height = '100%';
    watermarkDiv.style.backgroundImage = `url(${watermarkUrl})`;
    watermarkDiv.style.backgroundRepeat = 'repeat';
    watermarkDiv.style.zIndex = '9999';
    watermarkDiv.style.pointerEvents = 'none';
  };

  // 当 watermarkUrl 更新时执行的 useEffect
  useEffect(() => {
    if (watermarkUrl) {
      restoreWatermark();
    }
  }, [watermarkUrl]);
  useEffect(() => {
    if (watermarkUrl) {
      restoreWatermark();
    }
  }, [watermarkUrl]);

  // 监控水印元素的 style 属性变化
  useEffect(() => {
    const checkAndRestoreWatermark = () => {
      const watermarkDiv = document.querySelector(watermarkSelector);
      if (!watermarkDiv || watermarkDiv.style.backgroundImage !== `url(${watermarkUrl})`) {
        restoreWatermark();
      }
    };

    const styleObserver = new MutationObserver(checkAndRestoreWatermark);

    styleObserver.observe(document.body, { childList: true, subtree: true, attributes: true, attributeFilter: ['style'] });

    checkAndRestoreWatermark(); // 初始检查

    return () => styleObserver.disconnect();
  }, [watermarkUrl]);
  useEffect(() => {
    const watermarkDiv = document.querySelector(watermarkSelector);
    if (!watermarkDiv) {
      restoreWatermark();
      return;
    }

    const styleObserver = new MutationObserver(() => {
      if (!document.querySelector(watermarkSelector)) {
        restoreWatermark();
      } else {
        const currentWatermarkDiv = document.querySelector(watermarkSelector);
        if (currentWatermarkDiv.style.display === 'none' || currentWatermarkDiv.style.visibility === 'hidden') {
          restoreWatermark();
        }
      }
    });

    styleObserver.observe(document.body, { childList: true, subtree: true, attributes: true, attributeFilter: ['style'] });

    return () => styleObserver.disconnect();
  }, [watermarkUrl]);
  useEffect(() => {
    const watermarkDiv = document.getElementById(watermarkId);
    if (!watermarkDiv) {
      restoreWatermark();
      return;
    }

    const styleObserver = new MutationObserver(() => {
      restoreWatermark();
    });

    styleObserver.observe(watermarkDiv, { attributes: true, attributeFilter: ['style'] });

    return () => styleObserver.disconnect();
  }, [watermarkUrl]);
  // 使用 MutationObserver 监控 DOM 变化和样式变化的 useEffect
  useEffect(() => {
    // 检查 id 和 token 是否存在
    if (!id || !token) {
      return;
    }

    // 创建水印
    const newWatermarkUrl = createWatermark();
    setWatermarkUrl(newWatermarkUrl);
    
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.removedNodes.length) {
          mutation.removedNodes.forEach(node => {
            const watermarkDiv = document.getElementById(watermarkId);
            if (node.id === watermarkId || node.contains(document.getElementById(watermarkId))) {
              restoreWatermark();
            }
          });
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true, characterData: true });

    const bodyObserver = new MutationObserver(() => {
      if (!document.getElementById(watermarkId)) {
        restoreWatermark();
      }
    });
    
    bodyObserver.observe(document.body, { attributes: true, attributeFilter: ['style', 'class'] });

    const headObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          restoreWatermark();
        }
      });
    });
    headObserver.observe(document.head, { childList: true, attributes: true, subtree: true });
    // 清理逻辑
    return () => {
      observer.disconnect();
      bodyObserver.disconnect();
    };
  }, [id, token, watermarkUrl]);

  // 定期检查水印是否被隐藏
  useEffect(() => {
    const intervalId = setInterval(() => {
      const watermarkDiv = document.getElementById(watermarkId);
      if (watermarkDiv) {
        const classList = watermarkDiv.classList;
        const isHidden = hiddenClasses.some(hiddenClass => classList.contains(hiddenClass));
        if (isHidden) {
          hiddenClasses.forEach(hiddenClass => classList.remove(hiddenClass));
          restoreWatermark();
        }
      }
    }, 500); // 每 500 毫秒检查一次

    return () => clearInterval(intervalId);
  }, [watermarkUrl]);

  return null;
};

export default Watermark;
