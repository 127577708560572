import React, { useEffect, useState, useCallback } from 'react';
import NavigationBar from '../pages/NavigationBar';

function ExcavatePage() {
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [orderBy, setOrderBy] = useState('star_level');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async (pageNum) => {
    setLoading(true);
    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');
    const orderDirectionValue = orderDirection === 'asc' ? '0' : '1';

    if (id && token) {
      try {
        const response = await fetch('https://turn.vup.name/api/v1/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id,
            token,
            num: 24,
            page: pageNum,
            order_by: orderBy,
            order_direction: orderDirectionValue
          })
        });

        if (response.ok) {
          const result = await response.json();
          setData(prevData => pageNum === 1 ? result.data.items : [...prevData, ...result.data.items]);
          setPage(pageNum);
        } else {
          console.error('Error fetching data');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [orderBy, orderDirection]);

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) {
        return;
      }
      fetchData(page + 1);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [page, loading, fetchData]);

  const handleOrderByChange = (e) => {
    setOrderBy(e.target.value);
  };

  const handleOrderDirectionChange = (e) => {
    setOrderDirection(e.target.value);
  };

  const handleSubmit = () => {
    fetchData(1);
  };

  const openModal = (item) => {
    setModalData(item);
  };
  
 
  const cardClass = (item, index) => {
    let classes = "card card-bordered bg-white shadow-lg p-2 m-2 cursor-pointer";
  
    if (item.star_metrics && item.star_metrics !== "None") {
      classes += " outline-dotted outline-2 outline-offset-2 outline-indigo-500";
    }
  
    // 检查是否需要加载背景图片
    if (item.live_status === 1) {
      // 添加一个用于标记的类
      classes += " live-background";
  
      // 创建一个新的Image对象加载图片
      const img = new Image();
      img.src = item.cover_from_user;
  
      
    }
  
    return classes;
  };

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone/i.test(navigator.userAgent);
  };
  
  const copyToClipboard = text => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };
  
  
  const formatStarMetrics = (metrics) => {
    if (metrics === "None") {
      return "无";
    }
    try {
      const metricsArray = JSON.parse(metrics.replace(/'/g, '"'));
      return (
        <table className="table-auto w-full mx-auto bg-white rounded-lg">
          <thead>
            <tr className="text-center">
              <th>金额</th>
              <th>周期</th>
            </tr>
          </thead>
          <tbody>
            {metricsArray.map((metric, index) => (
              <tr key={index} className="text-center">
                <td>{metric.Val}￥</td>
                <td>{metric.DateRange}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    } catch (e) {
      console.error('Error parsing star_metrics:', e);
      return "解析错误";
    }
  };
  

  return (
    <div className="bg-slate-50 min-h-screen">
      <NavigationBar />
      <div className="container mx-auto p-4">
        <div className="flex gap-4 mb-4">
          <select value={orderBy} onChange={handleOrderByChange} className="select select-bordered">
            <option value="star_level">星级</option>
            <option value="live_day">开播天数</option>
            <option value="new_anchor">新主播</option>
          </select>
          <select value={orderDirection} onChange={handleOrderDirectionChange} className="select select-bordered">
            <option value="asc">正序</option>
            <option value="desc">倒序</option>
          </select>
          <button onClick={handleSubmit} className="btn btn-primary">提交</button>
        </div>

 
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-2">
          {data.map((item, index) => (
            <div key={index} className={cardClass(item)} onClick={() => openModal(item)}>
            {item.live_status === 1 && (
            <div className="absolute top-0 left-0 w-full h-full bg-cover bg-center shadow-lg rounded-2xl" style={{ backgroundImage: `url(${item.cover_from_user})` }}></div>
            )}
            <div className={`relative z-10  ${item.live_status === 1 ? ' backdrop-filter backdrop-blur-xl bg-black/15 absolute rounded-xl shadow-xl' : ''}`}>
            <div key={index} className="grid grid-cols-3">
              <figure className="col-span-1">
                <img src={item.face} alt="Avatar" className="h-12 w-12 object-cover  rounded-full" />
              </figure>
              <div className={`col-span-2 card-body p-2 text-xs ${item.live_status === 1 ? 'text-white font-bold' : ''}`}>
                <h2 className="card-title text-sm truncate">{item.uname}</h2>
                <p className="truncate"> {item.uid}</p>
                {/* 其他文本信息 */}
            </div>
            </div>
                <div className="col-span-2 card-body p-2 text-xs">
                <p className="mb-1 leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold">新主播: {item.is_new_anchor ? '是' : '否'}</p>
                <p className="mb-1 leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold">签约: {item.is_signed ? '是' : '否'}</p>
                <p className="mb-1 leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold">繁星: {
                  item.star_level === '0' ? "非繁星" :
                  item.star_level === '2' ? "⭐⭐⭐⭐" :
                  item.star_level === '3' ? "⭐⭐⭐⭐⭐" :
                  item.star_level === '4' ? "预备星" :
                  item.star_level
                }</p>
                <p className="leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold">开播天数: {item.valid_live_day}</p>
                {/* 如果还有其他信息，也可以继续添加在这里 */}
              </div>
              </div>
              </div>
          ))}
        </div>

        </div>

        {modalData && (
        <div className="modal modal-open">
          <div className="modal-box p-6" 
              style={{ 
                backgroundImage: `url(${modalData.face})`, 
                backgroundSize: 'cover' 
              }}>
            <div className="backdrop-filter backdrop-blur-3xl bg-black/10 p-6 rounded-lg">
              <img src={modalData.face} alt="Avatar" className="h-18 w-18 hidden" />
          <h3 className="font-bold text-lg text-white mb-4">{modalData.uname}</h3>
          <div className="flex items-center mb-2 leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold">
          <p>UID: {modalData.uid}</p>
          <button onClick={() => copyToClipboard(modalData.uid)} className="ml-2 btn btn-xs" >复制</button>
          </div>
          <div className="flex items-center mb-2 leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold">
          <p>房间号: {modalData.room_id}</p>
            <button onClick={() => copyToClipboard(modalData.room_id)} className="ml-2 btn btn-xs" > 复制 </button>
            </div>
          <p className="mb-2 leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold">是否新主播: {modalData.is_new_anchor ? '是' : '否'}</p>
          <p className="mb-2 leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold">是否签约: {modalData.is_signed ? '是' : '否'}</p>
          <p className="mb-2 leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold">繁星等级: {
            modalData.star_level === '0' ? "非繁星" :
            modalData.star_level === '2' ? "⭐⭐⭐⭐" :
            modalData.star_level === '3' ? "⭐⭐⭐⭐⭐" :
            modalData.star_level === '4' ? "预备星" :
            modalData.star_level
          }</p>
          <div className="mb-2 leading-relaxed  bg-gray-200 py-2 pb-4 px-4 shadow rounded-lg font-bold">繁星任务: {formatStarMetrics(modalData.star_metrics)}</div>
          <p className="mb-2 leading-relaxed bg-gray-200 py-2 pl-4 shadow rounded-lg font-bold">有效开播天数: {modalData.valid_live_day}</p>
          <div className="modal-action w-full">
          <button className="btn btn-neutral" onClick={() => {
                    const url = isMobileDevice()
                      ? `bilibili://space/${modalData.uid}`
                      : `https://message.bilibili.com/?spm_id_from=333.999.0.0#/whisper/mid${modalData.uid}`;
                    window.open(url, '_blank');
                  }}> 打开Bilibili私信 </button>
          <button className="btn btn-accent" onClick={() => {
                    const url = isMobileDevice()
                      ? `/query?uid=${modalData.uid}`
                      : `/query?uid=${modalData.uid}`;
                    window.open(url, '_blank');
                  }}> 数据 </button>
          <button className="btn" onClick={() => setModalData(null)}>关闭</button>
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-lg" onClick={() => setModalData(null)}>✕</button>
        </div>
      </div>
    </div>
  </div>
)}

    </div>
  );
}

export default ExcavatePage;
