import React from 'react';
import HomePage from './pages/HomePage';
import ExcavatePage from './pages/ExcavatePage';
import QueryPage from './pages/QueryPage';



import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    
    <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/excavate" element={<ExcavatePage />} />
          <Route path="/query" element={<QueryPage />} />
        </Routes>
    </Router>
  );
}

export default App;
